<template>
  <v-main>
    <a-header />
    <p-form
      ref="dataform"
      caption="SGK Parametre"
      :actions="formActions"
      :models="models"
      :autoCloseAfter="true"
      :refreshHook="refreshDataGrid"
    >
      <template slot="content">
        <v-container fluid>
          <v-row>
            <v-col cols="3">
              <v-text-field
                v-model="models.id"
                label="ID"
                dense
                disabled
              ></v-text-field>
            </v-col>
            <v-col>
              <v-select
                dense
                label="Yıl"
                v-model="models.year"
                :items="computedYearItems"
                :rules="validation().required().toRules('Yıl')"
              ></v-select>
            </v-col>
          </v-row>
          <v-row>
            <v-col>
              <v-text-field
                v-model="models.january"
                label="Ocak Tavan"
                dense
                :rules="validation().required().numeric().toRules('Ocak Tavan')"
              ></v-text-field>
            </v-col>
            <v-col>
              <v-text-field
                v-model="models.july"
                label="Temmuz Tavan"
                dense
                :rules="
                  validation().required().numeric().toRules('Temmuz Tavan')
                "
              ></v-text-field>
            </v-col>
          </v-row>
          <v-row>
            <v-col>
              <v-text-field
                v-model="models.sgk_rate"
                label="SGK Payı"
                dense
                :rules="validation().required().numeric().toRules('SGK Payı')"
              ></v-text-field>
            </v-col>
            <v-col>
              <v-text-field
                v-model="models.unemployment_rate"
                label="İşsizlik Payı"
                dense
                :rules="
                  validation().required().numeric().toRules('İşsizlik Payı')
                "
              ></v-text-field>
            </v-col>
          </v-row>
        </v-container>
      </template>
    </p-form>
    <p-datagrid
      ref="datagridInstance"
      :url="gridUrl"
      :selectable="gridSelectable"
      :uniqueColumn="gridUniqueColumn"
      :headers="gridHeaders"
      :exportable="gridExportable"
      :clearable="gridClearable"
      :filters="gridFilters"
      :rowActions="gridRowActions"
      :massActions="gridMassActions"
      :actions="gridActions"
      :searchable="gridSearchable"
      :icon="gridIcon"
      :caption="gridCaption"
    />
    <a-footer />
  </v-main>
</template>

<script>
export default {
  name: "sgk-params",
  components: {},
  data: (vm) => ({
    inEdit: false,
    gridUrl: "/setup/sgkParameterList",
    gridClearable: true,
    gridSearchable: true,
    gridSelectable: false,
    gridExportable: true,
    gridUniqueColumn: "id",
    gridIcon: "vaccines",
    gridCaption: "SGK Parametreleri",
    gridHeaders: [
      {
        text: "İşlemler",
        value: "action",
        sortable: false,
        unExportable: true,
      },
      { text: "Yıl", value: "year", align: "right", sortable: true },
      {
        text: "Tavan Ocak",
        align: "right",
        value: "january",
        sortable: true,
      },
      {
        text: "Tavan Temmuz",
        align: "right",
        value: "july",
        sortable: true,
      },
      {
        text: "SGK Payı",
        align: "right",
        value: "sgk_rate",
        sortable: true,
      },
      {
        text: "İşsizlik Payı",
        align: "right",
        value: "unemployment_rate",
        sortable: true,
      },
    ],
    gridFilters: [
      {
        type: "text",
        name: "year",
        label: "Yıl",
      },
    ],
    gridMassActions: [],
    gridRowActions: [
      {
        icon: "edit",
        hint: "Düzenle",
        color: "blue",
        action: (item) => {
          vm.inEdit = true;
          vm.$refs.dataform.showFetch(item, "edit", "Düzenle");
        },
      },
      {
        icon: "delete",
        hint: "Sil",
        color: "red",
        warn: true,
        action: async (item, self) => {
          try {
            await vm.axios.delete("/setup/sgkParameter/" + encodeURI(item.id));
            vm.$refs.datagridInstance.refresh();
          } catch (error) {
            vm.$dialog.error({ text: this.utils.getErrorMessage(error) });
          }
        },
        guard: (item, self) => {
            return true;
        },
      },
    ],
    gridActions: [
      {
        action: () => {
          vm.inEdit = false;
          vm.$refs.dataform.show("Ekle");
        },
      },
    ],
    //END OF DATAGRID

    //FORM
    models: {
      id: null,
      year: null,
      january: 0,
      july: 0,
      sgk_rate: null,
      unemployment_rate: null,
    },
    formActions: [
      {
        text: "Kaydet",
        api: {
          url: `/setup/createSgkParameter`,
          method: "put",
        },
        condition: null,
      },
      {
        text: "Güncelle",
        fetch: {
          url: "/setup/sgkParameter",
          id: "id",
        },
        api: {
          url: "/setup/updateSgkParameter",
          method: "patch",
        },
        condition: "edit",
      },
    ],
  }),
  computed: {
    computedYearItems() {
      let ret = [];
      for (let i = new Date().getFullYear() + 1; i >= 1999; i--) {
        ret.push({ value: `${i}`, text: `${i}` });
      }
      return ret;
    },
  },
  watch: {},
  methods: {
    refreshDataGrid() {
      this.$refs.datagridInstance.refresh();
    },
  },
  async mounted() {},
  created() {},
};
</script>