var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-combobox',_vm._b({ref:"combobox",attrs:{"menu-props":{ maxHeight: '180' },"items":_vm.computedItems,"label":_vm.label,"chips":"","hide-selected":"","multiple":"","clearable":""},scopedSlots:_vm._u([{key:"selection",fn:function(d){return [_c('v-chip',_vm._b({key:JSON.stringify('__' + d.index),attrs:{"close":"","input-value":d.selected,"disabled":d.disabled},on:{"click":function($event){$event.stopPropagation();_vm.$refs[`innerInput_${d.item.split(':')[0]}`].focus()},"click:close":function($event){return d.parent.selectItem(d.item)}}},'v-chip',d.attrs,false),[_c('strong',{staticStyle:{"padding-right":"8px","color":"#666"}},[_vm._v(_vm._s(d.item.split(": ")[0])+":")]),(!_vm.hasLookups(d.item.split(':')[0]))?_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.data[d.item.split(':')[0]]),expression:"data[d.item.split(':')[0]]"}],ref:`innerInput_${d.item.split(':')[0]}`,staticStyle:{"min-width":"30px","max-width":"150px","max-height":"30px"},style:({
          width: (_vm.data[d.item.split(':')[0]] + '').length * 8 + 'px',
        }),attrs:{"type":"text"},domProps:{"value":(_vm.data[d.item.split(':')[0]])},on:{"keyup":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter"))return null;_vm.innerInputKeyUp(d.item.split(':')[0])},"input":function($event){if($event.target.composing)return;_vm.$set(_vm.data, d.item.split(':')[0], $event.target.value)}}}):_c('v-autocomplete',{ref:`innerInput_${d.item.split(':')[0]}`,staticClass:"inner-autocomplete",staticStyle:{"min-width":"30px","max-width":"150px","max-height":"50px"},attrs:{"auto-select-first":"","flat":"","dense":"","full-width":"","multiple":_vm.isMultipleLookups(d.item.split(':')[0]),"menu-props":{
          closeOnClick: false,
          closeOnContentClick: true,
          openOnClick: false,
        },"items":_vm.dataTextSourceLookups[d.item.split(':')[0]]},on:{"change":function($event){_vm.innerInputKeyUp(d.item.split(':')[0])}},scopedSlots:_vm._u([{key:"selection",fn:function({ item, index }){return [(index === 0)?_c('span',[_vm._v(_vm._s(item.text))]):_vm._e(),(index === 1)?_c('span',{staticClass:"grey--text caption",staticStyle:{"padding-left":"5px"}},[_vm._v("(+"+_vm._s(_vm.data[d.item.split(':')[0]].length - 1)+")")]):_vm._e()]}}],null,true),model:{value:(_vm.data[d.item.split(':')[0]]),callback:function ($$v) {_vm.$set(_vm.data, d.item.split(':')[0], $$v)},expression:"data[d.item.split(':')[0]]"}})],1)]}}]),model:{value:(_vm.model),callback:function ($$v) {_vm.model=$$v},expression:"model"}},'v-combobox',_vm.$attrs,false))
}
var staticRenderFns = []

export { render, staticRenderFns }