<template>
  <v-footer app dark>
    <v-spacer></v-spacer>
  </v-footer>
</template>
<script>
export default {
  name: "a-footer",
  data: () => ({}),
};
</script>
