<template>
  <v-main>
    <v-container fluid fill-height>
      <v-layout align-center justify-center>
        <v-progress-circular indeterminate color="green"></v-progress-circular>
      </v-layout>
    </v-container>
  </v-main>
</template>

<script>
import router from "./../../router";
import store from "./../../store";

export default {
  methods: {
    logout() {
      this.axios.post("/auth/logout");
      setTimeout(() => {
        localStorage.removeItem("accessToken");
        store.dispatch("doLogout");
        router.push("/auth/login");
      }, 500);
    },
  },
  created() {
    return this.logout();
  },
};
</script>
