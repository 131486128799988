<template>
  <v-main>
    <a-header />
    <p-form
      ref="dataform"
      caption="Saatlik Çalışma Parametresi"
      :actions="formActions"
      :models="models"
      :autoCloseAfter="true"
      :refreshHook="refreshDataGrid"
      :defaultValues="{ status: true }"
    >
      <template slot="content">
        <v-container fluid>
          <v-row>
            <v-col cols="3">
              <v-text-field
                v-model="models.id"
                label="ID"
                dense
                disabled
              ></v-text-field>
            </v-col>
            <v-col>
              <v-select
                dense
                label="Yıl"
                v-model="models.year"
                :items="computedYearItems"
                :rules="validation().required().toRules('Yıl')"
              ></v-select>
            </v-col>
            <v-col>
              <p-lookup
                v-model="models.location.id"
                label="Konum"
                :rules="validation().required().toRules('Rol')"
                :fetchOptions="{ method: 'post' }"
                fetch="/lookup/locations"
                responseManipulator="data"
                :responseData="{ text: 'location_name', value: 'id' }"
                dense
              ></p-lookup>
            </v-col>
            <v-col>
              <v-select
                dense
                label="Durum"
                v-model="models.status"
                :items="[
                  { value: false, text: 'Pasif' },
                  { value: true, text: 'Aktif' },
                ]"
                :rules="validation().required().toRules('Durum')"
              ></v-select>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="3">
              <v-text-field
                v-model="models.monthly_parameters.january"
                label="Ocak"
                :rules="validation().required().numeric().toRules('Ocak')"
                dense
              ></v-text-field
            ></v-col>
            <v-col cols="3">
              <v-text-field
                v-model="models.monthly_parameters.february"
                label="Şubat"
                :rules="validation().required().numeric().toRules('Şubat')"
                dense
              ></v-text-field
            ></v-col>
            <v-col cols="3">
              <v-text-field
                v-model="models.monthly_parameters.march"
                label="Mart"
                :rules="validation().required().numeric().toRules('Mart')"
                dense
              ></v-text-field
            ></v-col>
            <v-col cols="3">
              <v-text-field
                v-model="models.monthly_parameters.april"
                label="Nisan"
                :rules="validation().required().numeric().toRules('Nisan')"
                dense
              ></v-text-field
            ></v-col>
          </v-row>
          <v-row>
            <v-col cols="3">
              <v-text-field
                v-model="models.monthly_parameters.may"
                label="Mayıs"
                :rules="validation().required().numeric().toRules('Mayıs')"
                dense
              ></v-text-field
            ></v-col>
            <v-col cols="3">
              <v-text-field
                v-model="models.monthly_parameters.june"
                label="Haziran"
                :rules="validation().required().numeric().toRules('Haziran')"
                dense
              ></v-text-field
            ></v-col>
            <v-col cols="3">
              <v-text-field
                v-model="models.monthly_parameters.july"
                label="Temmuz"
                :rules="validation().required().numeric().toRules('Temmuz')"
                dense
              ></v-text-field
            ></v-col>
            <v-col cols="3">
              <v-text-field
                v-model="models.monthly_parameters.august"
                label="Ağustos"
                :rules="validation().required().numeric().toRules('Ağustos')"
                dense
              ></v-text-field
            ></v-col>
          </v-row>
          <v-row>
            <v-col cols="3">
              <v-text-field
                v-model="models.monthly_parameters.september"
                label="Eylül"
                :rules="validation().required().numeric().toRules('Eylül')"
                dense
              ></v-text-field
            ></v-col>
            <v-col cols="3">
              <v-text-field
                v-model="models.monthly_parameters.october"
                label="Ekim"
                :rules="validation().required().numeric().toRules('Ekim')"
                dense
              ></v-text-field
            ></v-col>
            <v-col cols="3">
              <v-text-field
                v-model="models.monthly_parameters.november"
                label="Kasım"
                :rules="validation().required().numeric().toRules('Kasım')"
                dense
              ></v-text-field
            ></v-col>
            <v-col cols="3">
              <v-text-field
                v-model="models.monthly_parameters.december"
                label="Aralık"
                :rules="validation().required().numeric().toRules('Aralık')"
                dense
              ></v-text-field
            ></v-col>
          </v-row>
        </v-container>
      </template>
    </p-form>
    <p-datagrid
      ref="datagridInstance"
      :url="gridUrl"
      :selectable="gridSelectable"
      :uniqueColumn="gridUniqueColumn"
      :headers="gridHeaders"
      :exportable="gridExportable"
      :clearable="gridClearable"
      :filters="gridFilters"
      :rowActions="gridRowActions"
      :massActions="gridMassActions"
      :actions="gridActions"
      :searchable="gridSearchable"
      :icon="gridIcon"
      :caption="gridCaption"
    />
    <a-footer />
  </v-main>
</template>

<script>
export default {
  name: "hourly-work-parameters",
  components: {},
  data: (vm) => ({
    inEdit: false,
    gridUrl: "/setup/workPerHourList",
    locations: {},
    gridClearable: true,
    gridSearchable: true,
    gridSelectable: false,
    gridExportable: true,
    gridUniqueColumn: "id",
    gridIcon: "hourglass_empty",
    gridCaption: "Saatlik Çalışma Parametreleri",
    gridHeaders: [
      {
        text: "İşlemler",
        value: "action",
        sortable: false,
        unExportable: true,
      },
      {
        text: "Lokasyon",
        value: "location",
        align: "right",
        sortable: true,
        callable: (item) => {
          // return vm.locations;
          return vm.locations[item.location.id] || item.location.id;
        },
      },
      { text: "Yıl", value: "year", align: "right", sortable: true },
      {
        text: "Durum",
        value: "status",
        sortable: false,
        callable(item) {
          return item.status ? "Aktif" : "Pasif";
        },
        format: (item) => {
          return item.status ? "green--text" : "red--text";
        },
      },
      {
        text: "Ocak",
        align: "right",
        value: "january",
        sortable: false,
        callable: (item) =>
          item.monthly_parameters && item.monthly_parameters.january,
      },
      {
        text: "Şubat",
        align: "right",
        value: "february",
        sortable: false,
        callable: (item) =>
          item.monthly_parameters && item.monthly_parameters.february,
      },
      {
        text: "Mart",
        align: "right",
        sortable: false,
        value: "march",
        callable: (item) =>
          item.monthly_parameters && item.monthly_parameters.march,
      },
      {
        text: "Nisan",
        align: "right",
        sortable: false,
        value: "april",
        callable: (item) =>
          item.monthly_parameters && item.monthly_parameters.april,
      },
      {
        text: "Mayıs",
        align: "right",
        sortable: false,
        value: "may",
        callable: (item) =>
          item.monthly_parameters && item.monthly_parameters.may,
      },
      {
        text: "Haziran",
        align: "right",
        sortable: false,
        value: "june",
        callable: (item) =>
          item.monthly_parameters && item.monthly_parameters.june,
      },
      {
        text: "Temmuz",
        align: "right",
        sortable: false,
        value: "july",
        callable: (item) =>
          item.monthly_parameters && item.monthly_parameters.july,
      },
      {
        text: "Ağustos",
        align: "right",
        sortable: false,
        value: "august",
        callable: (item) =>
          item.monthly_parameters && item.monthly_parameters.august,
      },
      {
        text: "Eylül",
        align: "right",
        sortable: false,
        value: "september",
        callable: (item) =>
          item.monthly_parameters && item.monthly_parameters.september,
      },
      {
        text: "Ekim",
        align: "right",
        sortable: false,
        value: "october",
        callable: (item) =>
          item.monthly_parameters && item.monthly_parameters.october,
      },
      {
        text: "Kasım",
        align: "right",
        sortable: false,
        value: "november",
        callable: (item) =>
          item.monthly_parameters && item.monthly_parameters.november,
      },
      {
        text: "Aralık",
        align: "right",
        sortable: false,
        value: "december",
        callable: (item) =>
          item.monthly_parameters && item.monthly_parameters.december,
      },
    ],
    gridFilters: [
      {
        type: "select",
        name: "year",
        label: "Yıl",
        lookups: vm.getYearLookups(),
      },
      {
        type: "select",
        name: "status",
        label: "Durum",
        lookups: [
          { value: true, text: "Aktif" },
          { value: false, text: "Pasif" },
        ],
      },
      {
        type: "select",
        name: "location",
        label: "Konum",
        lookups: vm.getLocationLookups(),
      },
    ],
    gridMassActions: [],
    gridRowActions: [
      {
        icon: "edit",
        hint: "Düzenle",
        color: "blue",
        action: (item) => {
          vm.inEdit = true;
          vm.$refs.dataform.showFetch(item, "edit", "Düzenle");
        },
      },
      {
        icon: "delete",
        hint: "Sil",
        color: "red",
        warn: true,
        action: async (item, self) => {
          try {
            await vm.axios.delete("/setup/workPerHour/" + encodeURI(item.id));
            vm.$refs.datagridInstance.refresh();
          } catch (error) {
            vm.$dialog.error({ text: this.utils.getErrorMessage(error) });
          }
        },
        guard: (item, self) => {
          return true;
          // return item["aktif"];
        },
      },
    ],
    gridActions: [
      {
        action: () => {
          vm.inEdit = false;
          vm.$refs.dataform.show("Ekle");
        },
      },
    ],
    //END OF DATAGRID

    //FORM
    models: {
      id: null,
      monthly_parameters: {},
      location: {},
      status: null,
    },
    formActions: [
      {
        text: "Kaydet",
        api: {
          url: `/setup/createWorkPerHour`,
          method: "put",
        },
        condition: null,
      },
      {
        text: "Güncelle",
        fetch: {
          url: "/setup/workPerHour",
          id: "id",
        },
        api: {
          url: "/setup/updateWorkPerHour",
          method: "patch",
        },
        condition: "edit",
      },
    ],
  }),
  computed: {
    computedYearItems() {
      let ret = [];
      for (let i = new Date().getFullYear() + 1; i >= 1999; i--) {
        ret.push({ value: `${i}`, text: `${i}` });
      }
      return ret;
    },
  },
  watch: {},
  methods: {
    refreshDataGrid() {
      this.$refs.datagridInstance.refresh();
    },
    getYearLookups() {
      let ret = [];
      for (let i = new Date().getFullYear() + 1; i >= 1999; i--) {
        ret.push({ value: `${i}`, text: `${i}` });
      }
      return ret;
    },
    getLocationLookups() {
      let ret = [];
      let res = this.axios.post("/lookup/locations").then((res) => {
        for (let i in res.data.data) {
          let c = res.data.data[i];
          this.locations[c.id] = c.location_name;
          ret.push({ value: c.id, text: c.location_name });
        }
        this.$refs &&
          this.$refs.datagridInstance &&
          this.$refs.datagridInstance.redraw();
      });

      return ret;
    },
  },
  async mounted() {},
  async created() {},
};
</script>