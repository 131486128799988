<template>
  <v-main>
    <v-container fluid fill-height>
      <v-layout align-center justify-center>
        <v-flex xs12 sm8 md4>
          <v-card class="elevation-12" :loading="isLoading">
            <template slot="progress">
              <v-progress-linear
                height="6"
                indeterminate
                absolute
                style="margin-top: 62px"
              ></v-progress-linear>
            </template>
            <v-toolbar dark color="primary">
              <v-toolbar-title> Giriş Yapın </v-toolbar-title>
              <v-spacer></v-spacer>
            </v-toolbar>
            <v-card-text>
              <v-form ref="form" @keyup.native.enter="submit">
                <v-text-field
                  :disabled="isLoading"
                  v-model="merchantId"
                  required
                  prepend-icon="point_of_sale"
                  name="merchantId"
                  label="Şirket Kodu"
                  type="text"
                  :rules="validation().required().toRules('Şirket Kodu')"
                ></v-text-field>
                <v-text-field
                  :disabled="isLoading"
                  v-model="username"
                  required
                  prepend-icon="person"
                  name="username"
                  label="Kullanıcı Adı"
                  type="text"
                  :rules="validation().required().toRules('Kullanıcı Adı')"
                ></v-text-field>
                <v-text-field
                  :disabled="isLoading"
                  v-model="password"
                  required
                  id="password"
                  prepend-icon="lock"
                  name="password"
                  label="Parola"
                  :rules="validation().required().toRules('Parola')"
                  :append-icon="show ? 'mdi-eye' : 'mdi-eye-off'"
                  :type="inputType"
                  @click:append="
                    show = !show;
                    inputType = inputType == 'text' ? 'password' : 'text';
                  "
                ></v-text-field>
                <v-layout justify-end dense style="min-height: 50px">
                  <!-- <v-checkbox
                   :disabled='isLoading'
                    dense
                    v-model="remember"
                    :label="$t('pages.login.rememberMe')"
                  ></v-checkbox> -->
                </v-layout>
                <v-divider></v-divider>
              </v-form>
            </v-card-text>
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn :disabled="isLoading" color="primary" @click="submit">
                Giriş
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-flex>
      </v-layout>
    </v-container>
  </v-main>
</template>

<script>
import Vue from "vue";
import router from "@/router";
import store from "@/store";

export default {
  data() {
    return {
      isLoading: false,
      username: "",
      password: "",
      merchantId: "",
      show: false,
      inputType: "password",
    };
  },
  methods: {
    async submit() {
      if (!this.$refs.form.validate()) {
        return false;
      }
      this.isLoading = true;
      // alert(`process.env.BASEURL:`+process.env.VUE_APP_BASEURL);
      // console.log(process.env);

      var urlToLogin = `/auth/login`;
      //alert('urlToLogin:'+urlToLogin);
      var paramsToLogin = {
        merchantId: this.merchantId,
        userName: this.username,
        password: this.password,
      };

      try {
        var response = await this.axios.post(urlToLogin, paramsToLogin);
        var responseData = response.data;
        var userProperties = {
          auth: {
            scope: "default",
            token_type: "bearer",
            token: responseData.data.accessToken,
            refresh_token: null,
            user_id: responseData.data.id,
            revoked: 0,
            role: responseData.data.role,
          },
          user: {
            zone: "APP",
            userId: responseData.data.id,
            username: this.username,
            email: this.username,
            name: this.username,
            surname: this.username,
            active: 1,
            lastLogin: "",
            avatar: "",
            permissions: [],
            roles: [
              {
                roleId: responseData.data.role.id,
                role: responseData.data.role.name,
                roleName: responseData.data.role.name,
              },
            ],
            activeRole: {
              roleId: responseData.data.role.id,
              role: responseData.data.role.name,
              roleName: responseData.data.role.name,
            },
          },
        };

        //console.log(JSON.stringify(userProperties, null, 2));
        await store.dispatch("doLogin", userProperties).then((_) => {
          this.axios.post("/auth/permission");

          router.push("/secure/home");
        });
      } catch (error) {
        console.log(error);
        this.$dialog.error({ text: this.utils.getErrorMessage(error) });
      } finally {
        this.isLoading = false;
      }
    },
  },
};
</script>