<template>
  <v-main>
    <a-header />
    <p-datagrid
      ref="datagridInstance"
      :url="gridUrl"
      :selectable="gridSelectable"
      :uniqueColumn="gridUniqueColumn"
      :headers="gridHeaders"
      :exportable="gridExportable"
      :clearable="gridClearable"
      :filters="gridFilters"
      :rowActions="gridRowActions"
      :massActions="gridMassActions"
      :actions="gridActions"
      :searchable="gridSearchable"
      :icon="gridIcon"
      :caption="gridCaption"
    />
    <a-footer />
  </v-main>
</template>

<script>
export default {
  name: "locations",
  components: {},
  data: (vm) => ({
    inEdit: false,
    gridUrl: "/budget/budgetLists",
    gridClearable: true,
    gridSearchable: true,
    gridSelectable: false,
    gridExportable: true,
    gridUniqueColumn: "id",
    gridIcon: "stream",
    gridCaption: "Bütçe Tanımları",
    gridHeaders: [
      {
        text: "İşlemler",
        value: "action",
        sortable: false,
        unExportable: true,
      },
      { text: "ID", value: "id", align: "center", sortable: true },
      { text: "Lokasyon", value: "location_name", sortable: true },
      { text: "Yıl", value: "year", sortable: true },
      { text: "Tipi", value: "type", sortable: true },
      { text: "Açıklama", value: "description", sortable: true },
    ],
    gridFilters: [
      {
        type: "text",
        name: "merchant_id",
        label: "Şirket Kodu",
      },
      {
        type: "text",
        name: "location_name",
        label: "Konum Adı",
      },
      {
        type: "text",
        name: "year",
        label: "Yıl",
      },
    ],
    gridMassActions: [],
    gridRowActions: [
      {
        icon: "edit",
        hint: "Düzenle",
        color: "blue",
        action: (item) => {
          vm.inEdit = true;
          vm.$refs.dataform.showFetch(item, "edit", "Düzenle");
        },
      },
    ],
    gridActions: [
      {
        action: () => {
          vm.$router.push("/secure/budget/new");
        },
      },
    ],
    //END OF DATAGRID

    //FORM
    models: {},
  }),
  computed: {},
  watch: {},
  methods: {
    refreshDataGrid() {
      this.$refs.datagridInstance.refresh();
    },
  },
  async mounted() {},
  created() {},
};
</script>