export default {
    actions: {
        yes: 'Evet',
        no: 'Hayır',
        ok: "Tamam",
        close: "Kapat",
        clear: "Temizle",
        search: "Arama",
        doSearch: "Ara",
        filter: "Filtre",
        filters: "Filtreler",
        doFilter: "Filtrele",
        back: "Geri",
        save: "Kaydet",
        update: "Güncelle",
        send: "Yolla",
        add: "Ekle",
        addNew: "Yeni Ekle",
        edit: "Düzenle",
        delete: "Sil",
        deleteSelecteds: "Seçilileri Sil",
        more: 'daha',
        change: "Değiştir",
        create: "Oluştur",
        approveSelecteds: "Seçilileri Onayla",
        actions: "İşlemler",
        details: "Detay",
        doSend: "Gönder",
        select: "Seç",
        forward: "İlerle",
        warningSelection: "Lütfen bir seçim yapınız",
        saveAndClose: "Kaydet ve Kapat",
        start: "Başla",
        addCondition: "Yeni Koşul Ekle",
        remove: "Kaldır",
        cancel: "Vazgeç",
        deleteAction: "Silme İşlemi",
        addNewRow: "Yeni Satır Ekle",
        view: "Görüntüle",
        moreActions: "Dİğer İşlemler..",
        resend: "Tekrar Yolla",
    },
    app: {
        reTryNow: "Şimdi Dene",
        reconnecting: "Tekrar Bağlanıyor",
        notConnectedReTryInSecs: "Bağlı değil, tekrar denenecek {sec}",
        notConnectedReTryInSecsWithCors: "Bağlı değil (CORS?), tekrar denenecek {sec}",
        aScriptError: "Bir Betik Hatası",
        showErrors: "Hataları Göster",
    },
    messages: {
        checking: "Denetleniyor...",
        success: "Başarıyla tamamlandı",
        loadingPleaseStandBy: "Yükleniyor, lütfen bekleyin...",
        noItemFoundToList: "Listelenecek öğe bulunamadı.",
        lastLogin: "Son Giriş",
        areYouSure: "Emin misiniz?",
        indicatesRequiredFields: "zorunlu alanları işaret eder",
        warning: "Uyarı",
        confirm: "Onay",
        dataTooHugeToExport: "En fazla {limit} satır çıktı alabilirsiniz. Çıktısını almak istediğiniz veri {current} satır içermektedir. Dilerseniz {limit} satır veriyi alabilir ya da sistemin size çıktıyı alıp göndermesini isteyebilirsiniz.",
        exportLimitedData: "{limit} Satır Veri Al",
        exportAllDataBackground: "Çıktı al gönder ({limit} Satır)",
        exportingProgress: "Çıktınız hazırlanıyor.",
        downloadLinkIsReady: "İndirme bağlantısı hazır",
        youCanDownloadByClickingHere: "Çıktı indirilmeyi bekliyor. Buraya tıklayarak indirebilirsiniz",
        willMailYourExport: "Çıktınız hazır olduğunda {mail} adresine iletilecektir",
        successMail: "Mail başarıyla gönderildi",
        pleaseStandBy: "Lütfen bekleyin...",
    },
    errors: {
        error: "Hata",
        anErrorOccured: "Bir hata oluştu",
        unExpectedError: "Beklenmeyen bir hata oluştu",
        unknownNetworkError: "Bilinmeyen bir ağ hatası",
        noSessionOrTimeout: "Oturum yok ya da zaman aşımına uğradı",
        noSessionOrTimeoutNeedRefreshService: "Oturum yok ya da zaman aşımına uğradı (token yineleme çağrılmadı)",
        youCanNotPerformThisOperation: "Bu işlemi gerçekleştiremezsiniz",
        serviceNotFound: "Servis Bulunamadı: {service}",
        serviceReturnedAnUnknownError: "Servis bilinmeyen bir hata bildirdi",
        wrongUsernameOrPassword: "Geçersiz kullanıcı adı ya da parola"
    },
    validation: {
        required: "{input} alanı gerekli",//{input}
        email: "Geçerli bir e-posta adresi olmalı",//{input}
        same: "'{value}' değerine eşit olmalı",//{input}
        confirmPassword: "Girilen parola aynı olmalı",//{input}
        different: "'{value}' değerine eşit olmamalı",//{input}
        url: "Geçerli bir URL olmalı",    //{input}
        alpha: "Yalnızca A..Z arası harfler",//{input}
        alphaTr: "Yalnızca A..Z arası harfler (Türkçe Karakterler Dahil)",//{input}
        alphaTrNum: "Yalnızca A..Z arası harfler (Türkçe Karakterler ve Sayılar Dahil)",//input
        alphaDash: "Yalnızca A..Z arası harfler ve - _ kullanınız",//{input}
        alphaNum: "Yalnızca A..Z arası harfler ve 0..9 arası rakamlar kullanınız",//{input}
        alphaDashNum: "Yalnızca A..Z arası harfler, 0..9 arası rakamlar ve  - _  kullanınız",//{input}
        date: "Geçerli bir tarih olmalı",//{input}
        time: "Geçerli bir saat olmalı",//{input}
        integer: "Geçerli bir tam sayı olmalı",//{input}
        numeric: "Geçerli bir sayı olmalı",//{input}
        vkn: "Geçerli bir sayı ve {min} ya da {max} hane olmalı",//{input}
        ip: "Geçerli bir IP adresi olmalı", //{input}
        between: "{min} - {max} arasında olmalı",//{input}
        digits: "{digits} hane olmalı",//{input}
        digitsBetween: "{min} - {max} hane arasında olmalı",//{input}
        charBetween: "{min} - {max} karakter arasında olmalı",//{input}
        max: "En fazla {max} olabilir",//{input}
        charMax: "En fazla {max} karakter olabilir",//{input}
        min: "En az {min} olmalıdır",//{input}
        charMin: "En az {min} karakter olmalıdır",//{input}
        in: "[{set}] kümesi içerisinde yer almalıdır",//{input}
        notIn: "[{set}] kümesi içerisinde yer almamalıdır",//{input}
        after: "{date} tarihinden sonra olmalıdır",//{input}
        afterEqual: "{date} tarihi ve sonrası olmalıdır",//{input}
        afterTime: "{time} saatinden sonra olmalıdır",//{input}
        afterTimeEqual: "{time} saati ve sonrası olmalıdır",//{input}
        before: "{date} tarihinden önce olmalıdır",//{input}
        beforeEqual: "{date} tarihi ve öncesi olmalıdır",//{input}
        beforeTime: "{time} saatinden önce olmalıdır",//{input}
        beforeTimeEqual: "{time} saati ve öncesi olmalıdır",//{input}
        unique: "{input} daha önce kullanılmış.",
        uniqueOk: "\"{input}\" kullanılabilir.",
        passwordNum: "En az 1 rakam içermelidir.",
        passwordAZaz: "En az 1 büyük 1 küçük harf içermelidir",
        passwordSpecial: "En az bir özel karakter içermelidir",
        passwordFreeSeq: "Aynı karakterden çok fazla kullanıyorsunuz",
        passwordDontAllowTheese: "{these} alanlarını kullanmamalısınız",
        phone: "Geçersiz telefon numarası",
        iban: "Toplam {digit} hane olmalı",
        positivity: "Girdiğiniz değer pozitif olmalı",
        negativity: "Girdiğiniz değer negatif olmalı"
    },
    dataTable: {
        quickSearch: "Hızlı Arama",
        detailedSearch: "Detaylı Arama",
        smartSearch: "Akıllı Arama",
        clearSearch: "Aramayı Temizle",
        clearSorting: "Sıralamayı Temizle",
        export: "Çıktı Al",
        actions: "Aksiyonlar",
        addNew: "Yeni Ekle",
        list: "Liste",
        filters: "Filtreler",
        none: "Yok",
        exportedOnTheDate: "{date} Tarihinde Alındı",
        preparingYourDump: "Çıktınız hazırlanyor...",
        yourDumpIsReady: "Çıktınız hazır!",
        chooseFieldsToShow: "Görüntülemek istediğiniz sütunları seçin",
        chooseFieldsToShowLimited: "{count} sütunu gizlediniz",
        restoreView: "Görünümü Sıfırla",
        showHideHint: "  Sütunları işaretleyerek görüntüleyip / gizleyebilirsiniz",
        overflowSettings: "Taşma Ayarları",
        overflowedText: "Taşan metin",
        makeOverflowedTextTo: "Taşan Veriyi",
        wrap: "Alt Satıra Al",
        hide: "Gizle",
        leaveAsIs: "Olduğu Gibi Bırak",
        charLimit: "Karakter Limiti",
    },
    serverErrors: {
        'LOGIN_ERROR': "Geçersiz giriş bilgileri",
    },
}