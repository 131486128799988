<template>
  <v-main>
    <a-header />
      <p>TODO</p>
    <a-footer />
  </v-main>
</template>

<script>
export default {
  name: "locations",
  components: {},
  data: (vm) => ({}),
  computed: {},
  watch: {},
  methods: {
    refreshDataGrid() {
      this.$refs.datagridInstance.refresh();
    },
  },
  async mounted() {},
  created() {},
};
</script>