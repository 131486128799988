<template>
  <v-main>
    <a-header />
    <v-container fluid>
      <v-card :loading="loading">
        <v-card-title>Profil</v-card-title>
        <v-card-text>
          <v-text-field
            label="Kullanıcı Adı"
            v-model="user.user_name"
            disabled
          />
          <v-text-field label="Şirket" v-model="user.merchant_name" disabled />
          <v-text-field label="Ad" v-model="user.name" disabled />
          <v-text-field label="Soyad" v-model="user.surname" disabled />
          <v-text-field label="E-Posta" v-model="user.email_address" disabled />
          <v-text-field label="Telefon" v-model="user.phone_number" disabled />
          <p>Rol: {{ user.role && user.role.name }}</p>
          <p>
            Lokasyon(lar):
            <span
              v-for="(loc, index) in user.location_auth"
              :key="'loc_' + index"
            >
              {{ loc.location_name }},
            </span>
          </p>
        </v-card-text>
      </v-card>
    </v-container>
    <a-footer />
  </v-main>
</template>

<script>
import store from "@/store";

export default {
  name: "user-profile",
  components: {},
  data: () => ({
    loading: true,
    user: {},
  }),
  computed: {},
  watch: {},
  async mounted() {
    let id = store.state.user.userId;
    try {
      var resp = await this.axios.get("/users/user/" + id);
      this.user = resp.data.data;
    } catch (e) {
      this.$dialog.error({ text: this.utils.getErrorMessage(e) });
    } finally {
      this.loading = false;
    }
  },
  created() {},
};
</script>