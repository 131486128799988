import Vue from 'vue';

import pUniqueTextfield from "@/components/devinim/p-unique-textfield";
import pCheckedTextfield from "@/components/devinim/p-checked-texfield";
import pSpinner from "@/components/devinim/p-spinner";
import pDatagrid from "@/components/devinim/p-datagrid";
import pForm from "@/components/devinim/p-form";
import pImporter from "@/components/devinim/p-importer";
import pServerSideImporter from "@/components/devinim/p-server-side-importer";
import pSinglePhotoUploader from "@/components/devinim/p-single-photo-uploader";
import pLookup from "@/components/devinim/p-lookup";
import pPeriodPicker from "@/components/devinim/p-period-picker";
import pSearchCombobox from "@/components/devinim/p-search-combobox";
import pTabForm from "@/components/devinim/p-tab-form";
import pSideInfoBar from "@/components/devinim/p-side-info-bar";
import pCurrencyTextfield from "@/components/devinim/p-currency-textfield";

import aBreadcrumbs from "@/components/app/a-breadcrumbs";
import aFooter from "@/components/app/a-footer";
import aHeader from "@/components/app/a-header";

import VueApexCharts from 'vue-apexcharts';

Vue.component('p-unique-textfield', pUniqueTextfield);
Vue.component('p-checked-texfield', pCheckedTextfield);
Vue.component('p-spinner', pSpinner);
Vue.component('p-datagrid', pDatagrid);
Vue.component('p-form', pForm);
Vue.component('p-importer', pImporter);
Vue.component('p-server-side-importer', pServerSideImporter);
Vue.component('p-single-photo-uploader', pSinglePhotoUploader);
Vue.component('p-lookup', pLookup);
Vue.component('p-search-combobox', pSearchCombobox);
Vue.component('p-period-picker', pPeriodPicker);
Vue.component('p-tab-form', pTabForm);
Vue.component('p-side-info-bar', pSideInfoBar);
Vue.component('p-currency-textfield', pCurrencyTextfield);

Vue.component('a-breadcrumbs', aBreadcrumbs);
Vue.component('a-footer', aFooter);
Vue.component('a-header', aHeader);

Vue.component('v-chart', VueApexCharts);