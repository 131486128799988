<template>
  <v-main>
    <a-header />
    <p-form
      ref="dataform"
      caption="Değişken"
      :actions="formActions"
      :models="models"
      :autoCloseAfter="true"
      :refreshHook="refreshDataGrid"
      :defaultValues="{ status: true }"
    >
      <template slot="content">
        <v-container fluid>
          <v-row>
            <v-col cols="3">
              <v-text-field
                v-model="models.id"
                label="ID"
                dense
                disabled
              ></v-text-field>
            </v-col>
            <v-col>
              <v-select
                dense
                label="Durum"
                v-model="models.status"
                :items="[
                  { value: false, text: 'Pasif' },
                  { value: true, text: 'Aktif' },
                ]"
                :rules="validation().required().toRules('Durum')"
              ></v-select>
            </v-col>
          </v-row>
          <v-row>
            <v-col>
              <v-text-field
                v-model="models.salary_item"
                label="Maaş Kalemi"
                dense
                :rules="validation().required().toRules('Maaş Kalemi')"
              ></v-text-field>
            </v-col>
            <v-col>
              <v-text-field
                v-model="models.calculate_type"
                label="Hesaplama Tipi"
                dense
                :rules="validation().required().toRules('Hesaplama Tipi')"
              ></v-text-field>
            </v-col>
            <v-col>
              <v-text-field
                v-model="models.variable_group"
                label="Değişken Grubu"
                dense
                :rules="validation().required().toRules('Değişken Grubu')"
              ></v-text-field>
            </v-col>
          </v-row>
          <v-row>
            <v-col>
              <v-select
                dense
                label="SGK Dahil"
                v-model="models.is_include_sgk_flag"
                :items="[
                  { value: false, text: 'Hayır' },
                  { value: true, text: 'Evet' },
                ]"
                :rules="validation().required().toRules('SGK Dahil')"
              ></v-select>
            </v-col>
            <v-col>
              <v-select
                dense
                label="İş Oranı"
                v-model="models.is_work_ratio"
                :items="[
                  { value: false, text: 'Hayır' },
                  { value: true, text: 'Evet' },
                ]"
                :rules="validation().required().toRules('İş Oranı')"
              ></v-select>
            </v-col>
            <v-col>
              <v-select
                dense
                label="Rezerve"
                v-model="models.is_reserved"
                :items="[
                  { value: false, text: 'Hayır' },
                  { value: true, text: 'Evet' },
                ]"
                :rules="validation().required().toRules('Rezerve')"
              ></v-select>
            </v-col>
          </v-row>
        </v-container>
      </template>
    </p-form>
    <p-datagrid
      ref="datagridInstance"
      :url="gridUrl"
      :selectable="gridSelectable"
      :uniqueColumn="gridUniqueColumn"
      :headers="gridHeaders"
      :exportable="gridExportable"
      :clearable="gridClearable"
      :filters="gridFilters"
      :rowActions="gridRowActions"
      :massActions="gridMassActions"
      :actions="gridActions"
      :searchable="gridSearchable"
      :icon="gridIcon"
      :caption="gridCaption"
    />
    <a-footer />
  </v-main>
</template>

<script>
export default {
  name: "variables",
  components: {},
  data: (vm) => ({
    inEdit: false,
    gridUrl: "/setup/variableParameterList",
    gridClearable: true,
    gridSearchable: true,
    gridSelectable: false,
    gridExportable: true,
    gridUniqueColumn: "id",
    gridIcon: "webhook",
    gridCaption: "Değişkenler",
    gridHeaders: [
      {
        text: "İşlemler",
        value: "action",
        sortable: false,
        unExportable: true,
      },
      { text: "ID", value: "id", align: "center", sortable: true },
      { text: "Maaş Kalemi", value: "salary_item", sortable: true },
      { text: "Hesaplama Tipi", value: "calculate_type", sortable: true },
      { text: "Değişken Grubu", value: "variable_group", sortable: true },
      {
        text: "SGK Dahil",
        value: "is_include_sgk_flag",
        sortable: true,
        callable(item) {
          return item.is_include_sgk_flag ? "Evet" : "Hayır";
        },
        format: (item) => {
          return item.is_include_sgk_flag ? "green--text" : "red--text";
        },
      },
      {
        text: "İş Oranı",
        value: "is_work_ratio",
        sortable: true,
        callable(item) {
          return item.is_work_ratio ? "Evet" : "Hayır";
        },
        format: (item) => {
          return item.is_work_ratio ? "green--text" : "red--text";
        },
      },
      {
        text: "Rezerve",
        value: "is_reserved",
        sortable: true,
        callable(item) {
          return item.is_reserved ? "Evet" : "Hayır";
        },
        format: (item) => {
          return item.is_reserved ? "green--text" : "red--text";
        },
      },
      {
        text: "Durum",
        value: "status",
        sortable: false,
        callable(item) {
          return item.status ? "Aktif" : "Pasif";
        },
        format: (item) => {
          return item.status ? "green--text" : "red--text";
        },
      },
    ],
    gridFilters: [
      {
        type: "text",
        name: "salary_item",
        label: "Maaş Kalemi",
      },
      {
        type: "text",
        name: "calculate_type",
        label: "Hesaplama Tipi",
      },
      {
        type: "text",
        name: "variable_group",
        label: "Değişken Grubu",
      },
      {
        type: "select",
        name: "status",
        label: "Durum",
        lookups: [
          { value: true, text: "Aktif" },
          { value: false, text: "Pasif" },
        ],
      },
    ],
    gridMassActions: [],
    gridRowActions: [
      {
        icon: "edit",
        hint: "Düzenle",
        color: "blue",
        action: (item) => {
          vm.inEdit = true;
          vm.$refs.dataform.showFetch(item, "edit", "Düzenle");
        },
      },
      {
        icon: "delete",
        hint: "Sil",
        color: "red",
        warn: true,
        action: async (item, self) => {
          try {
            await vm.axios.delete(
              "/setup/variableParameter/" + encodeURI(item.id)
            );
            vm.$refs.datagridInstance.refresh();
          } catch (error) {
            vm.$dialog.error({ text: this.utils.getErrorMessage(error) });
          }
        },
        guard: (item, self) => {
          return true;
        },
      },
    ],
    gridActions: [
      {
        action: () => {
          vm.inEdit = false;
          vm.$refs.dataform.show("Ekle");
        },
      },
    ],
    //END OF DATAGRID

    //FORM
    models: {
      id: null,
      salary_item: null,
      calculate_type: null,
      is_include_sgk_flag: null,
      is_work_ratio: null,
      is_reserved: null,
      variable_group: null,
      status: null,
    },
    formActions: [
      {
        text: "Kaydet",
        api: {
          url: `/setup/createVariableParameter`,
          method: "put",
        },
        condition: null,
      },
      {
        text: "Güncelle",
        fetch: {
          url: "/setup/variableParameter/",
          id: "id",
        },
        api: {
          url: "/setup/updateVariableParameter",
          method: "patch",
        },
        condition: "edit",
      },
    ],
  }),
  computed: {},
  watch: {},
  methods: {
    refreshDataGrid() {
      this.$refs.datagridInstance.refresh();
    },
  },
  async mounted() {},
  created() {},
};
</script>