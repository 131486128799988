<template>
  <v-main>
    <a-header />
    <v-container fluid class="pb-12">
      <v-row no-gutters>
        <v-col cols="12" sm="6">
          <v-skeleton-loader
            style="height:105px"
            v-if="dummyLoading"
            type="list-item-avatar-three-line"
          ></v-skeleton-loader>
          <v-list three-line v-else>
            <v-list-item>
              <v-list-item-avatar>
                <v-img v-if="user.avatar" :src="user.avatar || ''"></v-img>
                <span v-else>{{user.name.substr(0, 1) + user.surname.substr(0, 1) }}</span>
              </v-list-item-avatar>
              <v-list-item-content>
                <v-list-item-title>Hoş geldiniz,</v-list-item-title>
                <v-list-item-subtitle>
                  {{user.name}} {{user.surname}},
                  <br />
                  {{user.activeRole.roleName}}
                </v-list-item-subtitle>
              </v-list-item-content>
            </v-list-item>
          </v-list>
        </v-col>
      </v-row>
      <!-- <template v-if="showDashboard == isDebugMode && isDebugMode == 'true'">
        <v-row>
          <v-col cols='12' md="4"> 
            <dashBoardeInvoice
              ref="dashBoardeInvoice"
              v-show="true"
            />
          </v-col>
          <v-col cols='12' md="4"> 
            <dashBoardeInvoiceError
              ref="dashBoardeInvoiceError"
              v-show="true"
            />
          </v-col>
          <v-col cols='12' md="4"> 
            <dashBoardeInvoiceMonthly
              ref="dashBoardeInvoiceMonthly"
              v-show="true"
            />
          </v-col>
        </v-row>
        <v-row>
          <v-col cols='12' md="4"> 
            <dashBoardeArchive
              ref="dashBoardeArchive"
              v-show="true"
            />
          </v-col>   
          <v-col cols='12' md="4"> 
            <dashBoardeArchiveError
              ref="dashBoardeArchiveError"
              v-show="true"
            />
          </v-col>
          <v-col cols='12' md="4"> 
            <dashBoardeArchiveMonthly
              ref="dashBoardeArchiveMonthly"
              v-show="true"
            />
          </v-col>
        </v-row>
        <v-row>
          <v-col cols='12' md="4"> 
            <dashBoardeWaybill
              ref="dashBoardeWaybill"
              v-show="true"
            />
          </v-col>
          <v-col cols='12' md="4"> 
            <dashBoardeWaybillError
              ref="dashBoardeWaybillError"
              v-show="true"
            />
          </v-col>
          <v-col cols='12' md="4"> 
            <dashBoardeWaybillMonthly
              ref="dashBoardeWaybillMonthly"
              v-show="true"
            />
          </v-col>
        </v-row>
      </template> -->
    </v-container>
    <a-footer />
  </v-main>
</template>

<script>
import store from "@/store";

// import dashBoardeInvoice from "@/views/pages/home/_dashBoard_eInvoice";
// import dashBoardeArchive from "@/views/pages/home/_dashBoard_eArchive";
// import dashBoardeWaybill from "@/views/pages/home/_dashBoard_eWaybill";
// import dashBoardeInvoiceMonthly from "@/views/pages/home/_dashBoard_eInvoice_monthly";
// import dashBoardeArchiveMonthly from "@/views/pages/home/_dashBoard_eArchive_monthly";
// import dashBoardeWaybillMonthly from "@/views/pages/home/_dashBoard_eWaybill_monthly";
// import dashBoardeInvoiceError from "@/views/pages/home/_dashBoard_eInvoice_error";
// import dashBoardeArchiveError from "@/views/pages/home/_dashBoard_eArchive_error";
// import dashBoardeWaybillError from "@/views/pages/home/_dashBoard_eWaybill_error";

//import dashBoardeLedger from "@/views/pages/home/_dashBoard_eLedger";

export default {
  name: "home",
  components: {
    // dashBoardeInvoice,
    // dashBoardeArchive,
    // dashBoardeWaybill,
    // dashBoardeInvoiceMonthly,
    // dashBoardeArchiveMonthly,
    // dashBoardeWaybillMonthly,
    // dashBoardeInvoiceError,
    // dashBoardeArchiveError,
    // dashBoardeWaybillError,

    //dashBoardeLedger,
  },
  data: () => ({
    user: store.state.user,
    dummyLoading: true,
    isDebugMode: process.env.VUE_APP_USE_TEST_APIS,
  }),
  computed: {},
  watch: {},
  mounted() {
    setTimeout(() => (this.dummyLoading = false), 800);
    this.showDashboard = this.$route.query.dashboard ?? this.$route.query.dashboard;
  },
  created() {}
};
</script>