<style>
.menu-group .v-list-item__icon.v-list-group__header__append-icon {
  margin-left: 0 !important;
  min-width: auto !important;
}
.menu-item .v-list-item__title {
  white-space: normal !important;
}
.menu-sub-group .v-list-item__icon.v-list-group__header__prepend-icon {
  margin-right: 5px !important;
}
.menu-sub-group .v-list-item__icon.v-list-group__header__prepend-icon i {
  margin-left: 14px !important;
}
</style>
<style lang="scss" scoped>
.v-icon-rotate {
  transform: rotate(-180deg);
}
</style>
<template>
  <div>
    <v-navigation-drawer
      v-model="drawer"
      :clipped="$vuetify.breakpoint.lgAndUp"
      fixed
      app
      :mini-variant.sync="mini"
    >
      <v-list dense nav>
        <template v-for="(item, index) in itemsComputed">
          <v-divider v-if="item.divider" :key="index"></v-divider>
          <v-list-group
            v-else-if="item.children"
            :key="item.text"
            :value="isGroupActive(item)"
            class="menu-group"
            :prepend-icon="item.icon"
          >
            <template v-slot:activator>
              <v-list-item dense class="menu-item">
                <v-list-item-title style="margin-left: -28px">{{
                  item.text
                }}</v-list-item-title>
              </v-list-item>
            </template>

            <template v-for="(child, i) in item.children">
              <v-list-item
                v-show="!mini"
                v-if="!child.children"
                dense
                :key="i"
                :to="child.route"
                class="menu-item"
              >
                <v-list-item-icon>
                  <v-icon style="margin-left: 30px">{{
                    child.icon || "keyboard_arrow_right"
                  }}</v-icon>
                </v-list-item-icon>
                <v-list-item-title style="margin-left: -10px">{{
                  child.text
                }}</v-list-item-title>
              </v-list-item>
              <v-list-group
                v-else
                sub-group
                no-action
                :key="i"
                :value="isGroupActive(child)"
                class="menu-sub-group"
                prepend-icon="arrow_drop_down"
              >
                <template v-slot:activator>
                  <v-list-item dense class="menu-item">
                    <v-list-item-title style="margin-left: 0">{{
                      child.text
                    }}</v-list-item-title>
                  </v-list-item>
                </template>
                <template v-for="(grandson, j) in child.children">
                  <v-list-item
                    dense
                    :key="j"
                    :to="grandson.route"
                    class="menu-item"
                  >
                    <v-list-item-icon>
                      <v-icon style="margin-left: -15px">{{
                        grandson.icon || "keyboard_arrow_right"
                      }}</v-icon>
                    </v-list-item-icon>
                    <v-list-item-title style="margin-left: -25px">{{
                      grandson.text
                    }}</v-list-item-title>
                  </v-list-item>
                </template>
              </v-list-group>
            </template>
          </v-list-group>
          <v-list-item
            v-else
            dense
            class="menu-item"
            :key="item.text"
            :to="item.route"
          >
            <v-list-item-icon>
              <v-icon>{{ item.icon }}</v-icon>
            </v-list-item-icon>
            <v-list-item-title style="margin-left: -20px">{{
              item.text
            }}</v-list-item-title>
          </v-list-item>
        </template>
      </v-list>
    </v-navigation-drawer>
    <v-app-bar
      app
      :clipped-left="$vuetify.breakpoint.lgAndUp"
      color="grey darken-3"
      dark
      fixed
    >
      <v-app-bar-nav-icon @click.stop="mini = !mini" />
      <v-toolbar-title style="width: 300px" class="ml-0 pl-3">
        <v-img
          :src="theme.assets.logo"
          max-width="80"
          max-height="50"
          contain
        ></v-img>
        <span class="hidden-sm-and-down">
          <small
            v-if="showEnv"
            style="position: absolute; left: 65px; bottom: -4px"
          >
            <small>
              <small style="color: #e1bee7">[{{ envName }}]</small>
            </small>
          </small>
        </span>
      </v-toolbar-title>
      <v-text-field
        v-show="false"
        flat
        solo-inverted
        hide-details
        prepend-inner-icon="search"
        :label="$t('actions.search')"
        class="hidden-sm-and-down"
      ></v-text-field>
      <v-spacer></v-spacer>

      <v-menu
        v-if="false"
        offset-y
        max-height="375"
        v-model="showMainNotificationsMenu"
      >
        <template v-slot:activator="{ on }">
          <v-btn icon v-on="on" ref="showMainNotificationsMenuButtonRef">
            <v-badge
              :content="Object.keys(notifierData).length"
              :value="Object.keys(notifierData).length"
              color="error"
              overlap
            >
              <v-icon>notifications</v-icon>
            </v-badge>
          </v-btn>
        </template>
        <v-list width="550">
          <template v-for="(item, index) in notifierData">
            <v-list-item
              v-bind:key="index"
              :href="item.action || null"
              @click="item.removeOnClick && removeNotification(item)"
              three-line
            >
              <v-list-item-action>
                <v-icon
                  v-if="item.type == 'progress'"
                  :class="rotatingClass"
                  :color="getNotificationIconColor(item)"
                  >{{ item.icon }}</v-icon
                >
                <v-icon v-else :color="getNotificationIconColor(item)">{{
                  item.icon
                }}</v-icon>
              </v-list-item-action>
              <v-list-item-content>
                <v-list-item-title>{{ item.caption }}</v-list-item-title>
                <v-list-item-subtitle>{{
                  item.subcaption
                }}</v-list-item-subtitle>
                <v-list-item-subtitle v-if="item.type != 'progress'">
                  <small>{{ item.text }}</small>
                </v-list-item-subtitle>
                <v-progress-linear
                  v-if="item.type == 'progress'"
                  :value="item.progress"
                  color="info"
                  height="15"
                  style="margin-top: 7px"
                >
                  <template v-slot:default="{ value }">
                    <small>{{ item.text }} %{{ (value + 0).toFixed(2) }}</small>
                  </template>
                </v-progress-linear>
              </v-list-item-content>
            </v-list-item>
            <v-divider
              v-bind:key="'dvd' + index"
              style="margin-right: 15px; margin-left: 55px"
            ></v-divider>
          </template>
          <v-list-item v-if="Object.keys(notifierData).length < 1">
            <v-list-item-action>
              <v-icon color="info">info</v-icon>
            </v-list-item-action>
            <v-list-item-content>
              <v-list-item-title>Yeni Bildirim Yok</v-list-item-title>
              <v-list-item-subtitle>
                Çıktı alma &amp; Eş zamanlı sonuçları gibi zaman alan işlemlerin
              </v-list-item-subtitle>
              <v-list-item-subtitle>
                bildirimlerini burada görebilirsiniz.
              </v-list-item-subtitle>
            </v-list-item-content>
          </v-list-item>
        </v-list>
        <!--<div style="width: 400px; height: 1px"></div>-->
      </v-menu>

      <v-menu offset-y max-width="240">
        <template v-slot:activator="{ on }">
          <v-btn icon large v-on="on">
            <v-avatar color="grey" size="32">
              <!-- <v-img v-if="user.avatar" :src="user.avatar || ''"></v-img> -->
              <span>{{
                user.name.substr(0, 1) + user.surname.substr(0, 1)
              }}</span>
            </v-avatar>
          </v-btn>
        </template>
        <v-list dense>
          <v-list-item>
            <v-list-item-content>
              <v-list-item-subtitle>
                {{ user.name }} {{ user.surname }}
                <!-- {{ user.username }} -->
                <span class="caption" style="color: #c00">{{
                  user.activeRole.roleName
                }}</span>
              </v-list-item-subtitle>
              <!-- <span
                class="caption font-regular"
                style="color:#999"
              >{{$t('messages.lastLogin')}}: {{user.lastLogin}}</span> -->
            </v-list-item-content>
          </v-list-item>
          <v-list-item to="/secure/user/profile">
            <v-list-item-action>
              <v-icon dense>manage_accounts</v-icon>
            </v-list-item-action>
            <v-list-item-content>
              <v-list-item-title>Profil</v-list-item-title>
            </v-list-item-content>
          </v-list-item>
          <v-divider></v-divider>
          <v-list-item to="/auth/logout">
            <v-list-item-action>
              <v-icon dense>logout</v-icon>
            </v-list-item-action>
            <v-list-item-content>
              <v-list-item-title>Çıkış</v-list-item-title>
            </v-list-item-content>
          </v-list-item>
        </v-list>
      </v-menu>
    </v-app-bar>
  </div>
</template>


<script>
import store from "@/store";

export default {
  name: "a-header",
  data: (vm) => ({
    showEnv: false,
    envName: process.env.VUE_APP_ENV_NAME,
    showMainNotificationsMenu: false,
    rotatingClass: "",
    drawer: null,
    user: store.state.user,
    mini: false,
    isLoading: false,
    notifierData: {},
    itemsDemo: [],
    items: [
      { icon: "home", text: "Giriş", route: "/" },
      { divider: true },
      {
        icon: "rule",
        text: "Parametreler",
        children: [
          {
            text: "Lokasyonlar",
            route: "/secure/setup/locations",
          },
          {
            text: "SGK Parametreleri",
            route: "/secure/setup/sgk-parameters",
          },
          {
            text: "Saatlik Çalışma Parametreleri",
            route: "/secure/setup/hourly-work-parameters",
          },
          {
            text: "Personel Tipleri",
            route: "/secure/setup/staff",
          },
          {
            text: "Değişken Tanımlama",
            // icon: "track_changes",
            route: "/secure/setup/variables", //just for menu expand / collapse purpose
          },
          {
            text: "Bütçe Değişkenleri",
            // icon: "pix",
            route: "/secure/setup/budget-variables",
          },
        ],
      },

      {
        icon: "contactless",
        text: "Bütçe Tanımlama",
        route: "/secure/budget/definitions",
      },
      {
        icon: "calculate",
        text: "Bütçe Hesaplama",
        route: "#2", //match containing /secure/eInvoice/setup links in this group
      },

      {
        icon: "stacked_bar_chart",
        text: "Raporlar",
        route: "/secure/eArchive/home",
        check: "/eArchive/e-archive",
        children: [
          {
            text: "Detay Rapor",
            route: "#3",
          },
          {
            text: "Karşılaştırmalı Rapor",
            route: "#4",
          },
          {
            text: "Rezerv Raporu",
            route: "#5",
          },
        ],
      },
      {
        icon: "receipt_long",
        text: "Actual Yükle",
        route: "#6",
      },

      { divider: true },
      {
        icon: "settings",
        text: "Ayarlar",
        route: "/secure/settings",
        children: [
          {
            text: "Kullanıcılar",
            route: "/secure/settings/users",
            check: "/secure/settings/users",
          },
        ],
      },
      { divider: true },
      { icon: "logout", text: "Çıkış", route: "/auth/logout" },
    ],
  }),
  methods: {
    isEqualOrIsContains(pageRoute, targetRoute) {
      if (pageRoute == targetRoute) {
        return true;
      }
      if (!targetRoute || !targetRoute.endsWith("*") < 0) {
        return false;
      }
      let tr = targetRoute.substring(0, targetRoute.length - 1);
      let pr = pageRoute.substring(
        0,
        Math.min(targetRoute.length - 1, pageRoute.length - 1)
      );

      return tr == pr;
    },
    isGroupActive(item) {
      var p = this.$router.currentRoute.path;
      // console.log(`current: ${p}`);
      return (
        this.isEqualOrIsContains(p, item.route) ||
        (item.children &&
          item.children.filter((child) => {
            return (
              this.isEqualOrIsContains(p, child.route) ||
              (child.children &&
                child.children.filter((grandson) =>
                  this.isEqualOrIsContains(p, grandson.route)
                ).length > 0)
            );
          }).length > 0)
      );
    },
  },
  watch: {},
  computed: {
    itemsComputed() {
      const appender = (itemz, ret) => {
        for (let i in itemz) {
          var item = itemz[i];
          if (!item.children) {
            //console.log(`route: ${item.route}`);
            if (
              !item.route ||
              this.acl().has("POST", item.route) ||
              (item.check && this.acl().has("POST", item.check))
            ) {
              ret.push(item);
              continue;
            }
          } else {
            var children = [];
            for (let c in item.children) {
              var child = item.children[c];
              if (!child.children) {
                //console.log(`child.route: ${child.route}`);
                if (
                  !child.route ||
                  this.acl().has("POST", child.route) ||
                  (child.check && this.acl().has("POST", child.check))
                ) {
                  children.push(child);
                }
              } else {
                var grandsons = [];
                for (let g in child.children) {
                  var grandson = child.children[g];
                  if (
                    !grandson.route ||
                    this.acl().has("POST", grandson.route) ||
                    (grandson.check && this.acl().has("POST", grandson.check))
                  ) {
                    grandsons.push(grandson);
                  }
                }
                if (grandsons.length > 0) {
                  child.children = grandsons;
                  children.push(child);
                }
              }
            }
            if (children.length > 0) {
              item.children = children;
              ret.push(item);
            }
          }
        }
      };

      var includeDemoRoutes = process.env.VUE_APP_INCLUDE_DEMO_FUNCS === "true";

      const resultList = [];

      appender(this.items, resultList);
      console.log(resultList);

      if (includeDemoRoutes) {
        appender(this.itemsDemo, resultList);
      }
      return resultList;
    },
  },
  created() {},
  async mounted() {},
};
</script>