import Vue from 'vue'
import Router from 'vue-router'

import store from './store';

/* ------------- Routes ------------- */
import App from './app'
import Home from './views/home'

import Login from './views/auth/login'
import Logout from './views/auth/logout'
// import Forget from './views/auth/forget.vue'
// import Reset from './views/auth/reset.vue'
// import ConfirmRecovery from './views/auth/confirm-recovery.vue'

import BudgetDefinitions from './views/pages/budget/budget-definitions.vue';
import BudgetNew from './views/pages/budget/budget-new.vue';

import Users from './views/pages/settings/users';
import Locations from './views/pages/setup/locations';
import SGKParameters from './views/pages/setup/sgk-parameters';
import HourlyWorkParameters from './views/pages/setup/hourly-work-parameters';
import Staff from './views/pages/setup/staff';

import Variables from './views/pages/setup/variables';
import BudgetVariables from './views/pages/setup/budget-variables';

import UserProfile from './views/pages/user/profile';


import e404 from './views/errors/404'

Vue.use(Router);

const router = new Router({
  mode: 'history',
  base: process.env.BASE_URL,
  routes: [
    //AUTHENTICATION & AUTHORIZATION
    {
      path: '/auth/login',
      name: 'login',
      component: Login
    },
    {
      path: '/auth/logout',
      name: 'logout',
      component: Logout
    },
    // {
    //   path: '/auth/forget',
    //   name: 'forget',
    //   component: Forget
    // },
    // {
    //   path: '/auth/confirmRecovery',
    //   name: 'confirm-recovery',
    //   component: ConfirmRecovery
    // },
    // {
    //   path: '/auth/reset',
    //   name: 'reset',
    //   component: Reset
    // },
    {
      path: '/',
      name: 'app',
      component: App
    },
    {
      path: '/secure/home',
      name: 'home',
      component: Home
    },

    /////////////////////////
    {
      path: '/secure/budget/definitions',
      name: 'budget-definitions',
      component: BudgetDefinitions
    },
    {
      path: '/secure/budget/new',
      name: 'budget-new',
      component: BudgetNew
    },
    {
      path: '/secure/settings/users',
      name: 'users',
      component: Users
    },
    {
      path: '/secure/setup/locations',
      name: 'locations',
      component: Locations
    },
    {
      path: '/secure/setup/sgk-parameters',
      name: 'sgk-parameters',
      component: SGKParameters
    },
    {
      path: '/secure/setup/hourly-work-parameters',
      name: 'hourly-work-parameters',
      component: HourlyWorkParameters
    },
    {
      path: '/secure/setup/staff',
      name: 'staff-type',
      component: Staff
    },
    {
      path: '/secure/setup/variables',
      name: 'variables',
      component: Variables
    },
    {
      path: '/secure/setup/budget-variables',
      name: 'budget-variables',
      component: BudgetVariables
    },
    {
      path: '/secure/user/profile',
      name: 'user-profile',
      component: UserProfile
    },

    ////////////////






    {
      path: "*",
      name: "404",
      component: e404
    },
    // {
    //   path: "*",
    //   name: "404",
    //   component: () => import(/* webpackChunkName: "404" */ './views/errors/404.vue')
    // }
  ]
})

router.beforeEach((to, from, next) => {

  store.dispatch('fetchAccessToken');

  //console.log('beforeEach, token:' + store.state.auth.token + " type: " + typeof store.state.auth.token);
  console.log('beforeEach');

  if (to.fullPath === '/') {
    if (store.state.auth.token) {
      console.log('wants to go /; redirect to /secure/home; token is alive');
      return next('/secure/home');
    }
    console.log('wants to go /; redirect to /login; token is dead');
    return next('/auth/login');
  }

  if (to.fullPath === '/auth/login') {
    if (store.state.auth.token) {
      console.log('wants to go /login; redirect to /secure/home; token is alive');
      return next('/secure/home');
    }
    console.log('wants to go /login; showing /login; token is dead');
    return next();
  }

  if (to.fullPath === '/auth/logout') {
    if (!store.state.auth.token) {
      console.log('wants to go /logout; redirect to /login; token is dead');
      return next('/auth/login');
    }
    console.log('wants to go /logout; showing /logout; token is alive');
    return next();
  }

  var secure = to.fullPath.startsWith("/secure");

  if (secure && !store.state.auth.token) {
    console.log('wants to /secure area; redirect to /login; token is dead');
    return next('/auth/login');
  }

  // if (!acl.guard(to.fullPath)) {
  //   console.log('aclGUARD blocked');
  //   return next("/");
  // } else {
  //   console.log('aclGUARD allowed');
  // }

  console.log(`wants to ${to.fullPath}; whis is secure: ${secure}; allowed; token is alive: ${!!store.state.auth.token} `);
  return next();
});


export default router;
