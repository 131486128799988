<template>
  <v-main>
    <a-header />
    <p-form
      ref="dataform"
      caption="Kullanıcı"
      :actions="formActions"
      :models="models"
      :autoCloseAfter="true"
      :refreshHook="refreshDataGrid"
      :afterFetch="afterFetchForm"
    >
      <template slot="content">
        <v-container fluid>
          <v-row>
            <v-col>
              <v-text-field
                v-if="inEdit"
                v-model="models.merchant_name"
                label="Şirket"
                dense
                disabled
              ></v-text-field>
              <v-text-field
                v-else
                v-model="models.merchant_id"
                label="Şirket No"
                dense
              ></v-text-field>
            </v-col>
          </v-row>
          <v-row>
            <v-col>
              <v-text-field
                v-model="models.user_name"
                label="Kullanıcı Adı"
                dense
                :disabled="inEdit"
              ></v-text-field>
            </v-col>
            <v-col>
              <p-lookup
                v-model="models.role.id"
                label="Rol"
                :rules="validation().required().toRules('Rol')"
                :fetchOptions="{ method: 'post' }"
                fetch="/lookup/rolesList"
                responseManipulator="data"
                :responseData="{ text: 'role_name', value: 'id' }"
                dense
              ></p-lookup>
            </v-col>
          </v-row>
          <v-row>
            <v-col>
              <v-text-field
                v-model="models.name"
                label="Ad"
                dense
                :rules="validation().required().toRules('Ad')"
              ></v-text-field>
            </v-col>
            <v-col>
              <v-text-field
                v-model="models.surname"
                label="Soyad"
                dense
                :rules="validation().required().toRules('Soyad')"
              ></v-text-field>
            </v-col>
          </v-row>
          <v-row>
            <v-col>
              <v-text-field
                v-model="models.email_address"
                label="E-Posta"
                dense
                :rules="validation().required().email().toRules('E-Posta')"
              ></v-text-field>
            </v-col>
            <v-col>
              <v-text-field
                v-model="models.phone_number"
                label="Telefon"
                dense
                :rules="validation().required().toRules('Telefon')"
              ></v-text-field>
            </v-col>
          </v-row>

          <v-row>
            <v-select
              v-model="models.location_auth"
              :items="locations"
              label="Lokasyon Seçin"
              item-text="location_name"
              item-value="id"
              return-object
              multiple
            >
              <template v-slot:selection="{ item, index }">
                <v-chip v-if="index < 4">
                  <span>{{ item.location_name }}</span>
                </v-chip>
                <span v-if="index == 4" class="grey--text text-caption">
                  (+{{ models.location_auth.length - 4 }} daha)
                </span>
              </template>
            </v-select>
            <!-- <v-col v-for="(loc, index) in locations" :key="'loc_' + index">
              <v-checkbox
                v-if="models.location_auth[index]"
                v-model="models.location_auth[index].location_status"
                :label="loc.location_name"
              ></v-checkbox>
            </v-col> -->
          </v-row>
        </v-container>
      </template>
    </p-form>
    <p-datagrid
      ref="datagridInstance"
      :url="gridUrl"
      :selectable="gridSelectable"
      :uniqueColumn="gridUniqueColumn"
      :headers="gridHeaders"
      :exportable="gridExportable"
      :clearable="gridClearable"
      :filters="gridFilters"
      :rowActions="gridRowActions"
      :massActions="gridMassActions"
      :actions="gridActions"
      :searchable="gridSearchable"
      :icon="gridIcon"
      :caption="gridCaption"
    />
    <a-footer />
  </v-main>
</template>

<script>
export default {
  name: "users",
  components: {},
  data: (vm) => ({
    inEdit: false,
    gridUrl: "/users/userList",
    gridClearable: true,
    gridSearchable: true,
    gridSelectable: false,
    gridExportable: true,
    gridUniqueColumn: "id",
    gridIcon: "person",
    gridCaption: "Kullanıcılar",
    gridHeaders: [
      {
        text: "İşlemler",
        value: "action",
        sortable: false,
        unExportable: true,
      },
      {
        text: "ID",
        align: "center",
        sortable: true,
        value: "id",
      },
      { text: "Şirket Kodu", value: "merchant_id", sortable: true },
      { text: "Şirket", value: "merchant_name", sortable: true },

      { text: "Kullanıcı", value: "user_name", sortable: true },
      { text: "Ad", value: "name", sortable: true },
      { text: "Soyad", value: "surname", sortable: true },
      { text: "E-mail", value: "email_address", sortable: true },
      { text: "Tel", value: "phone_number", sortable: false },
      {
        text: "Rol",
        value: "role",
        callable: (item) => item.role && item.role.name,
        sortable: false,
      },
      {
        text: "Konum",
        value: "location_auth",
        callable: (item) => {
          if (!item.location_auth) {
            return "";
          }
          let s = [];
          for (let i in item.location_auth) {
            let loc = item.location_auth[i];
            s.push(loc.location_name);
          }
          return s.join(", ");
        },
        sortable: false,
      },
    ],
    gridFilters: [
      {
        type: "text",
        name: "user_name",
        label: "Kullanıcı Adı",
      },
      {
        type: "text",
        name: "name",
        label: "Ad",
      },
      {
        type: "text",
        name: "surname",
        label: "Soyad",
      },
      {
        type: "select",
        name: "role_id",
        label: "Rol",
        lookups: vm.getRoleLookups(),
      },
    ],
    gridMassActions: [],
    gridRowActions: [
      {
        icon: "edit",
        hint: "Düzenle",
        color: "blue",
        action: (item) => {
          vm.inEdit = true;
          vm.$refs.dataform.showFetch(item, "edit", "Düzenle");
        },
      },
      // {
      //   icon: "delete",
      //   hint: "Sil",
      //   color: "red",
      //   warn: true,
      //   action: async (item, self) => {
      //     try {
      //       await vm.axios.delete("demo/" + encodeURI(item.id));
      //       vm.$refs.datagridInstance.refresh();
      //     } catch (error) {
      //       vm.$dialog.error({ text: error.response.data.message });
      //     }
      //   },
      //   guard: (item, self) => {
      //     return item["aktif"];
      //   },
      // },
    ],
    gridActions: [
      {
        action: () => {
          vm.inEdit = false;
          vm.$refs.dataform.show("Ekle");
        },
      },
    ],
    //END OF DATAGRID

    //FORM
    models: {
      id: null,
      merchant_id: null,
      merchant_name: null,
      user_name: null,
      name: null,
      surname: null,
      email_address: null,
      phone_number: null,
      role: {},
      location_auth: [],
    },
    locations: [],
    formActions: [
      {
        text: "Kaydet",
        api: {
          url: `/users/createUser`,
          method: "put",
        },
        condition: null,
      },
      {
        text: "Güncelle",
        fetch: {
          url: "/users/user/",
          id: "id",
        },
        api: {
          url: "/users/updateUser",
          method: "patch",
        },
        condition: "edit",
      },
    ],
  }),
  computed: {},
  watch: {},
  methods: {
    getRoleLookups() {
      let ret = [];
      this.axios.post("/lookup/rolesList").then((res) => {
        for (let i in res.data.data) {
          let c = res.data.data[i];
          ret.push({ value: c.id, text: c.role_name });
        }
        this.$refs &&
          this.$refs.datagridInstance &&
          this.$refs.datagridInstance.redraw();
      });

      return ret;
    },
    refreshDataGrid() {
      this.$refs.datagridInstance.refresh();
    },
    afterFetchForm() {
      // console.log(this.models.location_auth);
      // this.models.location_auth_clone = [];
      // for (let i in this.models.location_auth) {
      //   this.models.location_auth_clone.push({
      //     id: this.models.location_auth[i].id,
      //     location_name: this.models.location_auth[i].location_name,
      //   });
      // }
      // for (let i = 0; i < this.locations.length; i++) {
      //   if (!this.models.location_auth[i]) {
      //     let d = JSON.parse(JSON.stringify(this.locations[i]));
      //     d.location_status = false;
      //     this.models.location_auth[i] = d;
      //   }
      // }
    },
  },
  async mounted() {
    var response = await this.axios.post("/lookup/locations");
    this.locations = response.data.data;
  },
  created() {},
};
</script>