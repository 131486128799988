var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-dialog',{key:_vm.componentKey,attrs:{"fullscreen":_vm.$vuetify.breakpoint.smAndDown,"hide-overlay":"","transition":"dialog-bottom-transition","persistent":"","scrollable":"","max-width":"800px","origin":"bottom right","content-class":"side-info-bar-card","retain-focus":false},model:{value:(_vm.visible),callback:function ($$v) {_vm.visible=$$v},expression:"visible"}},[_c('v-card',{attrs:{"tile":""}},[_c('v-card-title',{staticClass:"headline pa-0",attrs:{"primary-title":""}},[_c('v-toolbar',{attrs:{"dark":"","color":"primary","flat":""},scopedSlots:_vm._u([(_vm.hasTab)?{key:"extension",fn:function(){return [_vm._t("tabs")]},proxy:true}:null],null,true)},[(_vm.hasCancel && _vm.$vuetify.breakpoint.smAndDown)?_c('v-btn',{attrs:{"icon":"","dark":"","disabled":_vm.loading},on:{"click":_vm.hide}},[_c('v-icon',[_vm._v("close")])],1):_vm._e(),_c('v-toolbar-title',[_vm._v(" "+_vm._s(_vm.caption)+" "),_c('small',{staticClass:"dialog-subcaption",class:{ sm: _vm.$vuetify.breakpoint.smAndDown }},[_c('small',[_c('small',[_vm._v(_vm._s(_vm.subCaption))])])])]),_c('v-spacer'),(_vm.$vuetify.breakpoint.smAndDown)?_c('v-toolbar-items',_vm._l((_vm.actions),function(action){return _c('div',{key:action.text},[(
                _vm.showActions &&
                action.condition == _vm.shownCondition &&
                !!action.text
              )?_c('v-btn',{staticStyle:{"height":"56px"},attrs:{"dark":"","text":"","large":"","loading":_vm.loading,"color":action.color || null},on:{"click":function($event){return _vm.runAction(action)}}},[_vm._v(_vm._s(action.text))]):_vm._e()],1)}),0):_vm._e()],1)],1),(_vm.openingFromServer)?_c('v-card-text',[_c('p',{staticClass:"pt-2"},[_vm._v(_vm._s(_vm.$t("messages.loadingPleaseStandBy")))]),_c('v-progress-linear',{staticClass:"mb-0",attrs:{"indeterminate":"","color":"primary"}})],1):_c('v-card-text',{staticStyle:{"overflow-y":"scroll","height":"auto"}},[_c('v-form',{ref:"form",model:{value:(_vm.valid),callback:function ($$v) {_vm.valid=$$v},expression:"valid"}},[_c('v-overlay',{attrs:{"value":_vm.loading,"absolute":""}}),_c('v-container',[_vm._t("content")],2)],1)],1),_c('v-card-actions',[_c('v-spacer'),(_vm.hasCancel && _vm.$vuetify.breakpoint.mdAndUp)?_c('v-btn',{staticClass:"mr-3",attrs:{"text":"","disabled":_vm.loading},on:{"click":_vm.hide}},[_vm._v(_vm._s(_vm.$t("actions.close")))]):_vm._e(),(_vm.$vuetify.breakpoint.mdAndUp)?_c('div',_vm._l((_vm.actions),function(action){return _c('div',{key:action.text},[(
              _vm.showActions &&
              action.condition == _vm.shownCondition &&
              !!action.text
            )?_c('v-btn',{staticStyle:{"min-width":"100px"},attrs:{"color":action.color || 'primary',"loading":_vm.loading},on:{"click":function($event){return _vm.runAction(action)}}},[_vm._v(_vm._s(action.text))]):_vm._e()],1)}),0):_vm._e()],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }