var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-main',[_c('a-header'),_c('p-form',{ref:"dataform",attrs:{"caption":"Değişken","actions":_vm.formActions,"models":_vm.models,"autoCloseAfter":true,"refreshHook":_vm.refreshDataGrid,"defaultValues":{ status: true }}},[_c('template',{slot:"content"},[_c('v-container',{attrs:{"fluid":""}},[_c('v-row',[_c('v-col',{attrs:{"cols":"3"}},[_c('v-text-field',{attrs:{"label":"ID","dense":"","disabled":""},model:{value:(_vm.models.id),callback:function ($$v) {_vm.$set(_vm.models, "id", $$v)},expression:"models.id"}})],1),_c('v-col',[_c('v-select',{attrs:{"dense":"","label":"Durum","items":[
                { value: false, text: 'Pasif' },
                { value: true, text: 'Aktif' },
              ],"rules":_vm.validation().required().toRules('Durum')},model:{value:(_vm.models.status),callback:function ($$v) {_vm.$set(_vm.models, "status", $$v)},expression:"models.status"}})],1)],1),_c('v-row',[_c('v-col',[_c('v-text-field',{attrs:{"label":"Maaş Kalemi","dense":"","rules":_vm.validation().required().toRules('Maaş Kalemi')},model:{value:(_vm.models.salary_item),callback:function ($$v) {_vm.$set(_vm.models, "salary_item", $$v)},expression:"models.salary_item"}})],1),_c('v-col',[_c('v-text-field',{attrs:{"label":"Hesaplama Tipi","dense":"","rules":_vm.validation().required().toRules('Hesaplama Tipi')},model:{value:(_vm.models.calculate_type),callback:function ($$v) {_vm.$set(_vm.models, "calculate_type", $$v)},expression:"models.calculate_type"}})],1),_c('v-col',[_c('v-text-field',{attrs:{"label":"Değişken Grubu","dense":"","rules":_vm.validation().required().toRules('Değişken Grubu')},model:{value:(_vm.models.variable_group),callback:function ($$v) {_vm.$set(_vm.models, "variable_group", $$v)},expression:"models.variable_group"}})],1)],1),_c('v-row',[_c('v-col',[_c('v-select',{attrs:{"dense":"","label":"SGK Dahil","items":[
                { value: false, text: 'Hayır' },
                { value: true, text: 'Evet' },
              ],"rules":_vm.validation().required().toRules('SGK Dahil')},model:{value:(_vm.models.is_include_sgk_flag),callback:function ($$v) {_vm.$set(_vm.models, "is_include_sgk_flag", $$v)},expression:"models.is_include_sgk_flag"}})],1),_c('v-col',[_c('v-select',{attrs:{"dense":"","label":"İş Oranı","items":[
                { value: false, text: 'Hayır' },
                { value: true, text: 'Evet' },
              ],"rules":_vm.validation().required().toRules('İş Oranı')},model:{value:(_vm.models.is_work_ratio),callback:function ($$v) {_vm.$set(_vm.models, "is_work_ratio", $$v)},expression:"models.is_work_ratio"}})],1),_c('v-col',[_c('v-select',{attrs:{"dense":"","label":"Rezerve","items":[
                { value: false, text: 'Hayır' },
                { value: true, text: 'Evet' },
              ],"rules":_vm.validation().required().toRules('Rezerve')},model:{value:(_vm.models.is_reserved),callback:function ($$v) {_vm.$set(_vm.models, "is_reserved", $$v)},expression:"models.is_reserved"}})],1)],1)],1)],1)],2),_c('p-datagrid',{ref:"datagridInstance",attrs:{"url":_vm.gridUrl,"selectable":_vm.gridSelectable,"uniqueColumn":_vm.gridUniqueColumn,"headers":_vm.gridHeaders,"exportable":_vm.gridExportable,"clearable":_vm.gridClearable,"filters":_vm.gridFilters,"rowActions":_vm.gridRowActions,"massActions":_vm.gridMassActions,"actions":_vm.gridActions,"searchable":_vm.gridSearchable,"icon":_vm.gridIcon,"caption":_vm.gridCaption}}),_c('a-footer')],1)
}
var staticRenderFns = []

export { render, staticRenderFns }