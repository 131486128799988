export default {
    default: {
        primary: '#1877bd',
        secondary: '#D1D2FE',
        accent: '#0d507c',
        error: '#FF5252',
        info: '#2196F3',
        success: '#4CAF50',
        warning: '#FFC107',
        assets: {
            loginLogo: "/img/themes/bupa/login-logo-3.png",
            logo: "/img/themes/bupa/logo.png"
        }
    },
};