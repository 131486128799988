  <template>

  <!-- <input 
    type="text" 
    v-model="model" 
    @blur="isInputActive = false" 
    @focus="isInputActive = true"
  /> -->

  <v-text-field
    ref="textfield"
    :label="label"
    :value="value"
    :loading="loading"
    type="text"
    :disabled="disabled || loading"
    v-model="currency"
    :rules="rules"
    :hint="hint"
    :persistent-hint="!!hint"
    @blur="isInputActive = false" 
    @focus="isInputActive = true"
    @keypress="preventNonCurrencyInput($event)"
    :outlined="outlined"
    :dense="dense"
    >
    </v-text-field>
</template>  
<script>
import Vue from "vue";
var _ = require("lodash");
import i18n from "@/plugins/vue-i18n";
export default {
  name: "p-currency-textfield", 
  props: {
    label: {
      type: String,
      required: false,
      default: () => ""
    },
    value: {
      // type: String,
      required: false
    },
    disabled: {
      type: Boolean
    },
    outlined: {
      type: Boolean,
      default: () => false
    },
    dense: {
      type: Boolean,
      default: () => false
    },
    rules: Array
  },
  data: vm => ({
    isInputActive: false,
    model: vm.value,
    loading: false,
    //currency: "",
  }),
  computed: {
    currency: {
        get: function() {
            if (this.isInputActive) {
                return this.value.toString();
            } else {

                if (!this.value) {
                  return "";
                }
                var temp = (Math.round(this.value * 100) / 100).toFixed(2).toString().replace(".", ",");
                temp = temp.replace(/(\d)(?=(\d\d\d)+(?!\d))/g, "$1.");

                //console.log("getter: " + temp);
                return temp;
            }
        },
        set: function(modifiedValue) {
            if (!modifiedValue) {
              return "";
            }
            var newValue = modifiedValue.replace(/[^0-9]\.]/g, '');
            if (isNaN(newValue)) {
                newValue ="";
            }

            //console.log("setter: " + newValue);
            this.$emit('input', newValue);
        }
    },
    hint() {
      return this.loading ? i18n.t("messages.checking") : null;
    },
  },
  watch: {
    // currency: {
    //   handler(val) {
    //     this.updateValue(val);
    //   }
    // }
  },
  methods: {
    updateValue: function(value) {
      this.$emit("input", value);
    },
    preventNonCurrencyInput(event) {
      //    /^[1-9]\d{0,2}(\.\d{3})*(,\d+)?$/ test
      //    /^[0-9][.\d]*(,\d+)?$/ test
      let r = new RegExp(/^-?[0-9]\d*(\.\d+)?$/);
      if (!r.test(event.key) && event.key !== '.' && event.key !== '-') return event.preventDefault();
    },
  },
  created() {},
  mounted() {}
};
</script>