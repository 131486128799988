<template>
  <v-main>
    <a-header />
    <p-form
      ref="dataform"
      caption="Bütçe Değişkeni"
      :actions="formActions"
      :models="models"
      :autoCloseAfter="true"
      :refreshHook="refreshDataGrid"
    >
      <template slot="content">
        <v-container fluid>
          <v-row>
            <v-col cols="3">
              <v-text-field
                v-model="models.id"
                label="ID"
                dense
                disabled
              ></v-text-field>
            </v-col>
            <v-col>
              <v-select
                dense
                label="Durum"
                v-model="models.status"
                :items="[
                  { value: false, text: 'Pasif' },
                  { value: true, text: 'Aktif' },
                ]"
                :rules="validation().required().toRules('Durum')"
              ></v-select>
            </v-col>
          </v-row>
          <v-row>
            <v-col>
              <p-lookup
                v-model="models.staff_type.id"
                label="Personel Tipi"
                :rules="validation().required().toRules('Personel Tipi')"
                :fetchOptions="{ method: 'post' }"
                fetch="/lookup/staffTypes"
                responseManipulator="data"
                :responseData="{ text: 'staff_type', value: 'id' }"
                dense
              ></p-lookup>
            </v-col>
            <v-col>
              <p-lookup
                v-model="models.cost_center_group"
                label="Maliyet Merkezi Grubu"
                :rules="
                  validation().required().toRules('Maliyet Merkezi Grubu')
                "
                :fetchOptions="{ method: 'post' }"
                fetch="/lookup/centerOfCostGroups"
                responseManipulator="data"
                disable-convert-to-int
                :responseData="{
                  text: 'cost_center_group',
                  value: 'cost_center_group',
                }"
                dense
              ></p-lookup>
            </v-col>
            <v-col>
              <v-text-field
                v-model="models.account_code"
                label="Hesap Kodu"
                :rules="validation().required().toRules('Hesap Kodu')"
                dense
              ></v-text-field>
            </v-col>
          </v-row>
        </v-container>
      </template>
    </p-form>
    <p-datagrid
      ref="datagridInstance"
      :url="gridUrl"
      :selectable="gridSelectable"
      :uniqueColumn="gridUniqueColumn"
      :headers="gridHeaders"
      :exportable="gridExportable"
      :clearable="gridClearable"
      :filters="gridFilters"
      :rowActions="gridRowActions"
      :massActions="gridMassActions"
      :actions="gridActions"
      :searchable="gridSearchable"
      :icon="gridIcon"
      :caption="gridCaption"
    />
    <a-footer />
  </v-main>
</template>

<script>
export default {
  name: "budget-variables",
  components: {},
  data: (vm) => ({
    inEdit: false,
    gridUrl: "/setup/variableAccountList",
    gridClearable: true,
    gridSearchable: true,
    gridSelectable: false,
    gridExportable: true,
    gridUniqueColumn: "id",
    gridIcon: "wallet",
    gridCaption: "Bütçe Değişkenleri",
    costCenters: {},
    gridHeaders: [
      {
        text: "İşlemler",
        value: "action",
        sortable: false,
        unExportable: true,
      },
      { text: "ID", value: "id", align: "center", sortable: true },
      {
        text: "Mailyet Merkezi Grubu",
        value: "cost_center_group",
        sortable: true,
      },
      {
        text: "Personel Tipi",
        value: "staff_type",
        sortable: true,
        callable(item) {
          return item.staff_type && item.staff_type.name;
        },
      },
      {
        text: "Hesap Kodu",
        value: "account_code",
        sortable: true,
      },
      {
        text: "Durum",
        value: "status",
        sortable: false,
        callable(item) {
          return item.status ? "Aktif" : "Pasif";
        },
        format: (item) => {
          return item.status ? "green--text" : "red--text";
        },
      },
    ],
    gridFilters: [
      {
        type: "select",
        name: "cost_center_group",
        label: "Maliyet Merkezi Grubu",
        lookups: vm.getCostLookups(),
      },
      {
        type: "select",
        name: "staff_type",
        label: "Personel Tipi",
        lookups: vm.getStaffLookups(),
      },
      {
        type: "text",
        name: "account_code",
        label: "Hesap Kodu",
      },
      {
        type: "select",
        name: "status",
        label: "Durum",
        lookups: [
          { value: true, text: "Aktif" },
          { value: false, text: "Pasif" },
        ],
      },
    ],
    gridMassActions: [],
    gridRowActions: [
      {
        icon: "edit",
        hint: "Düzenle",
        color: "blue",
        action: (item) => {
          vm.inEdit = true;
          vm.$refs.dataform.showFetch(item, "edit", "Düzenle");
        },
      },
      {
        icon: "delete",
        hint: "Sil",
        color: "red",
        warn: true,
        action: async (item, self) => {
          try {
            await vm.axios.delete(
              "/setup/variableAccount/" + encodeURI(item.id)
            );
            vm.$refs.datagridInstance.refresh();
          } catch (error) {
            vm.$dialog.error({ text: this.utils.getErrorMessage(error) });
          }
        },
        guard: (item, self) => {
          return true;
        },
      },
    ],
    gridActions: [
      {
        action: () => {
          vm.inEdit = false;
          vm.$refs.dataform.show("Ekle");
        },
      },
    ],
    //END OF DATAGRID

    //FORM
    models: {
      id: null,
      staff_type: {},
      cost_center_group: null,
      account_code: null,
      status: null,
    },
    formActions: [
      {
        text: "Kaydet",
        api: {
          url: `/setup/createVariableAccount`,
          method: "put",
        },
        condition: null,
      },
      {
        text: "Güncelle",
        fetch: {
          url: "/setup/variableAccount/",
          id: "id",
        },
        api: {
          url: "/setup/updateVariableAccount",
          method: "patch",
        },
        condition: "edit",
      },
    ],
  }),
  computed: {},
  watch: {},
  methods: {
    refreshDataGrid() {
      this.$refs.datagridInstance.refresh();
    },
    getCostLookups() {
      let ret = [];
      this.axios.post("/lookup/centerOfCostGroups").then((res) => {
        for (let i in res.data.data) {
          let c = res.data.data[i];
          // this.costCenters[c.cost_center_group] = c.cost_center_group;
          ret.push({
            value: `${c.cost_center_group}`,
            text: `${c.cost_center_group}`,
          });
        }
        // this.$refs &&
        //   this.$refs.datagridInstance &&
        //   this.$refs.datagridInstance.redraw();
      });

      return ret;
    },
    getStaffLookups() {
      let ret = [];
      this.axios.post("/lookup/staffTypes").then((res) => {
        for (let i in res.data.data) {
          let c = res.data.data[i];
          // this.costCenters[c.cost_center_group] = c.cost_center_group;
          ret.push({ value: c.id, text: c.staff_type });
        }
        // this.$refs &&
        //   this.$refs.datagridInstance &&
        //   this.$refs.datagridInstance.redraw();
      });

      return ret;
    },
  },
  async mounted() {},
  created() {},
};
</script>